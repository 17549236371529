import React, { useState, useEffect, useRef } from "react";
import {
  downIconTopbar,
  helpcenter,
  logout,
  settings,
  upIconTopbar,
  viewProfileIcon,
  wallet,
} from "./assets";

import { Box, Divider, Switch } from "@material-ui/core";
import i18n from "../../framework/src/i18config";
import { addClassIfArabic } from "./utils";



const useOnClickOutside = (ref: any, handler: any) => {
  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

interface IProfileSection {
  profileData: {
    id: string;
    type: string;
    attributes: {
      id: number;
      full_name: string;
      user_type: string;
      availability_status: boolean;
      gender: string;
      country_name: string;
      country_code: number;
      city: string;
      country_flag: string;
      phone_number: number;
      full_phone_number: string;
      email: string;
      coach_profile_status: null | string;
      coach_languages: string[];
      user_bio: string;
      date_of_birth: string;
      activated: boolean;
      profile_pic: string;
      introduction_video: null | string;
      preferred_coaching_language: string[];
      wallet_balance: number;
    };
  };
  walletBalance: {
    wallet_used: number;
    updated_wallet_amount: number;
  };
  userType: string;
  handleViewProfile: () => void;
  handleSettingNavigation: () => void;
  handleHelpCenterNavigation: () => void;
  handleWalletNavigation: () => void;
  handleLogoutModalOpen: () => void;
  showLogoutModal: boolean;
  showProfileTile: string;
  availableText: string;
}

const ProfileSection: React.FC<IProfileSection> = ({
  profileData,
  walletBalance,
  userType,
  showLogoutModal,
  showProfileTile,
  availableText,
  handleViewProfile,
  handleSettingNavigation,
  handleHelpCenterNavigation,
  handleWalletNavigation,
  handleLogoutModalOpen,
}) => {
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [isChecked, setIsChecked] = useState(true);

  const profileRef = useRef(null);
  const handleOpenProfile = () => {
    setOpenProfileModal((prev) => !prev);
    setIsChecked(true);
  };
  const toggleChecked = () => setIsChecked((prev) => !prev);

  useOnClickOutside(profileRef, () => {
    if (showLogoutModal) {
      setOpenProfileModal(openProfileModal);
    } else {
      setOpenProfileModal(false);
    }
  });

  const showUserType = () => {
    return userType || "Regular User";
  };

  return (
    <div className="profileSection" ref={profileRef}>
      <img
        src={profileData.attributes.profile_pic}
        alt="profile-image"
        className="profileImage"
      />
      <div className="profileName">{showProfileTile}</div>
      <div>
        {openProfileModal ? (
          <img
            src={upIconTopbar}
            className="arrow"
            data-test-id="close-profile-menu"
            onClick={handleOpenProfile}
          />
        ) : (
          <img
            src={downIconTopbar}
            className="arrow"
            data-test-id="open-profile-menu"
            onClick={handleOpenProfile}
          />
        )}
      </div>

      {openProfileModal && (
        <Box className="profileBox" style={{[i18n.language === 'en' ? 'right' : 'left']: 0,}}>
          <Box className="secondaryProfileBox">
            <Box className="avatarBox">
              <Box>
                <img
                  src={profileData.attributes.profile_pic}
                  alt="profile-image"
                  className="avatarStyle"
                />
              </Box>
              <Box style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                <p className="current-username">
                  {profileData.attributes.full_name}
                </p>
                <p className={`user-type ${addClassIfArabic(i18n)}`}>{showUserType()}</p>
              </Box>
            </Box>
            <div className="available-btn">
              <p>{availableText}</p>
              <Switch
                className="switch"
                data-test-id="switch"
                checked={isChecked}
                onChange={toggleChecked}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>
            <ul className="profile-option-list">
              <li
                className="option-list-item"
                data-test-id="handleViewProfile"
                onClick={handleViewProfile}
              >
                <img src={viewProfileIcon} alt="viewProfileIcon" />
                {i18n.t("client_dashboard_new.view_profile")}
              </li>
              <li
                className="option-list-item"
                data-test-id="handleSettingNavigation"
                onClick={handleSettingNavigation}
              >
                <img src={settings} alt="settings" />
                {i18n.t("client_dashboard_new.settings")}
              </li>
              <li
                className="option-list-item"
                data-test-id="handleHelpCenterNavigation"
                onClick={handleHelpCenterNavigation}
              >
                <img src={helpcenter} alt="helpcenter" />
                {i18n.t("client_dashboard_new.help_centre")}              </li>
              {(userType !== "Coach" && userType !== "كوتش") && (
                <Box
                  className="subTOPmenuPdT"
                  data-test-id="wallet-navigation"
                  onClick={handleWalletNavigation}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyTracks: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img src={wallet}  />
                    <Box className="walletLabel">{i18n.t("client_dashboard_new.wallet")}</Box>
                  </Box>
                  <Box className="walletAmount">
                    {walletBalance.wallet_used > 0
                      ? walletBalance.updated_wallet_amount
                      : profileData.attributes.wallet_balance}{" "}
                    <span className="currencyIcon">{i18n.t("client_dashboard_new.points")}</span>
                  </Box>
                </Box>
              )}
            </ul>
          </Box>
          <Divider className="dividers" />
          <div
            className="logoutBox"
            data-test-id="openlogoutmodal"
            onClick={handleLogoutModalOpen}
          >
            <img src={logout} />
            <p>{i18n.t("client_dashboard_new.logout")}</p>
          </div>
        </Box>
      )}
    </div>
  );
};

export default ProfileSection;
