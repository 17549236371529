import React from "react";
// Customizable Area Start
import {
    styled,
    Box,
    Switch,
    Divider,
    Typography,
    List,
    IconButton,
    ListItem,
    Drawer,
    ListItemIcon, Select, MenuItem, FormControl
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { Menu as MenuIcon } from "@material-ui/icons";
import moment from "moment";
import LayoutController, { Props } from "./LayoutController";
import Spinner from "../../../components/src/ProjectLoader.web";
import LogoutModal from "../../../components/src/LogoutModal.web";
import {
    upIconTopbar,
    downIconTopbar,
    viewProfileIcon,
    settings,
    helpcenter,
    logout,
    imgtwo,
    imgthree,
    imgfour,
    imgfive,
    imgsix,
    imgseven,
    clients,
    imgnine,
    ratingImg,
    coach_home,
    availability,
    ratingandreview,
    activeHomeIcon,
    activeSession,
    activeResources,
    activeClients,
    activeChat,
    activeAvailability,
    activeRatingReview,
    activeGoalProgress,
    activePayment,
    activeServeyAssessment,
    activeTaskImg,
    packages,
    activePackages,
    logofull,
    coachLogo,
    contact
} from "../../../components/src/assets";
import { wallet } from "./assets";
import ProfileSection from "../../../components/src/ProfileSection.web";
const configJSON = require("./config");
import { withTranslation } from "react-i18next";
import { addClassIfArabic, mobileSideMenuDirection } from "../../../components/src/utils";
// Customizable Area End

class Layout extends LayoutController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    showProfileTile = () => {
        const comma  = this.props.i18n.language === 'en'?',':'،';
        return this.props.t('layout_page.hi') + comma + ` ${this.state.profileData.attributes.user_type === "coach" ? this.props.t('layout_page.coach') : this.state.profileData.attributes.full_name}`
    }

    coachMenuItems = [
        { label: this.props.t('layout_page.home'), link: '/DashboardHome', icon: coach_home, activeIcon: activeHomeIcon },
        { label: this.props.t('layout_page.sessions'), link: '/UpcommingSession', icon: imgtwo, activeIcon: activeSession },
        { label: this.props.t('layout_page.coaching_resource'), link: '/CoachingResources', icon: imgsix, activeIcon: activeResources },
        { label: this.props.t('layout_page.clients'), link: '/CoachClientList', icon: clients, activeIcon: activeClients },
        { label: this.props.t('layout_page.availbility'), link: '/AvailabilityCalendar', icon: availability, activeIcon: activeAvailability },
        { label: this.props.t('layout_page.rating_review'), link: '/RatingAndReview', icon: ratingandreview, activeIcon: activeRatingReview },
        { label: this.props.t('layout_page.chat'), link: '/Chat', icon: imgseven, activeIcon: activeChat },
        { label: this.props.t('layout_page.reports'), link: '/Reports', icon: imgfour, activeIcon: activeGoalProgress },
        { label: this.props.t('layout_page.revenue_centre'), link: '/revenue', icon: imgnine, activeIcon: activePayment }
    ];

    clientMenuItems = [
        { label: this.props.t('layout_page.home'), link: '/ClientDashboard', icon: coach_home, activeIcon: activeHomeIcon },
        { label: this.props.t('layout_page.sessions'), icon: imgtwo, link: '/Sessions', activeIcon: activeSession },
        { label: this.props.t('layout_page.packages'), icon: packages, link: '/packages', activeIcon: activePackages },
        { label: this.props.t('layout_page.tasks'), icon: imgthree, link: '/TaskList', activeIcon: activeTaskImg },
        { label: this.props.t('layout_page.goals_progress'), icon: imgfour, link: '/GoalsAndProgress', activeIcon: activeGoalProgress },
        { label: this.props.t('layout_page.survey_assesment'), link: '/SurveyAssessment', icon: imgfive, activeIcon: activeServeyAssessment },
        { label: this.props.t('layout_page.library'), link: '/library', icon: imgsix, activeIcon: activeResources },
        { label: this.props.t('layout_page.chat'), link: '/ClientChat', icon: imgseven, activeIcon: activeChat },
        { label: this.props.t('layout_page.rating_review'), link: '/ClientRatingAndReview', icon: ratingImg, activeIcon: activeRatingReview },
        { label: this.props.t('client_dashboard_new.payment_billing'), link: '/ClientBillingandPayment', icon: imgnine, activeIcon: activePayment }
    ];

    renderSideBar = () => {
        return this.state.expand ? "288px" : "70px";
    }

    renderSidebarText = () => {
        return this.state.expand ? "block" : "none";
    }

    renderMobileSideBar = () => {
        const mobileMenuItems = this.state.profileData.attributes.user_type === 'coach' ? this.coachMenuItems : this.clientMenuItems;
        return <CustomDrawer anchor={mobileSideMenuDirection(this.props.i18n)} data-test-id="toggleDrawer" open={this.state.toggleDrawer} onClose={this.toggleDrawerHandler} className="mobile-sidebar">
            <div className={`wrapper_container ${addClassIfArabic(this.props.i18n)}`}>
                <div className="sidebar_collapse_style">
                    <List style={{ display: "flex", flex: "0 0 auto" }} >
                        <ListItem>
                            <ListItemIcon style={{ margin: "auto" }}>
                                <img src={logofull} alt="logofull" style={{ margin: "auto", width: "153px", height: "43px" }} />
                            </ListItemIcon>
                        </ListItem>
                    </List>
                    <div className="navigation_links">
                        <SidebarList>
                            {mobileMenuItems.map((item, index) => (
                                <NavLink to={item.link} style={{ textDecoration: "none" }} key={index}>
                                    <ListItem button style={sidebarStyles.margin_bottom} className='selected'>
                                        {window.location.pathname.toLowerCase().includes(item.link.toLowerCase()) ? <img src={item.activeIcon} style={sidebarStyles.sideImd} /> : <img src={item.icon} style={sidebarStyles.sideImd} />}
                                        <span className={`dashboardEmpty ${addClassIfArabic(this.props.i18n)}`} style={sidebarStyles.dashboardEmpty}>
                                            {item.label}
                                        </span>
                                    </ListItem>
                                </NavLink>
                            ))}
                        </SidebarList>
                    </div>
                    <BoxPositionChange3 to="/Contactus">
                        <Typography style={sidebarStyles.showSidebarContent}>{this.props.t("layout_page.our_support_team_is_happy_to_help")}</Typography>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px", marginTop: "10px", marginBottom: "10px" }}>
                            <div><img src={contact} alt="contact" style={{ width: "40.25px", height: "40px" }} /></div>
                            <div style={sidebarStyles.dashboardEmpty}>{this.props.t("layout_page.contact_us")}</div>
                        </div>
                    </BoxPositionChange3>
                </div>
            </div>
        </CustomDrawer>
    }

    renderSideBarFooter = () => {
        return this.state.expand ? sidebarStyles.showSidebarContent : sidebarStyles.hideSidebarContent
    }

    renderUserText = (userType:string|null)=>{
        return userType === "coach" ? this.props.t('layout_page.coach') : this.props.t("layout_page.coachee");
    }
    showUseType = () => {
        const userType = localStorage.getItem('userType');
        return this.renderUserText(userType);
    }

    render() {
        const menuItems = this.state.profileData.attributes.user_type === 'coach' ? this.coachMenuItems : this.clientMenuItems;
        const currentDate = moment();
        const day = this.props.t(`dayNames.${currentDate.format('dddd')}`);
        const date = currentDate.format('D');
        const month = this.props.t(`monthNames.${currentDate.format('MMMM')}`);
        const year = currentDate.format('YYYY');
        return (
            <MainSectionWrapper>
                <Spinner spinnerModal={this.state.isLoading} />
                <Box className="main__container">

                    {this.state.showSidebar ?
                        <Box className="main__wrapper" style={{ maxWidth: this.renderSideBar() }}>
                            <BoxPosition>
                                <div
                                    className="sidebar__wrapper"
                                    onMouseEnter={this.handleMouseEnter}
                                    onMouseLeave={this.handleMouseLeave}
                                >
                                    <Box style={{ display: "flex", flex: "0 0 auto", margin: "1rem auto" }} >
                                        {this.state.expand
                                            ? <Box style={{ margin: "auto" }}>
                                                <img src={logofull} alt="logofull" style={{ margin: "auto", width: "153px", height: "43px" }} />
                                            </Box>
                                            : <Box>
                                                <img src={coachLogo} alt="coachLogo" />
                                            </Box>}
                                    </Box>
                                    <div className="sidebar__sections">
                                        <SidebarList>
                                            {menuItems.map((item, index) => (
                                                <NavLink to={`${item.link}`} style={{ textDecoration: "none" }} key={index}>
                                                    <ListItem button style={sidebarStyles.margin_bottom} className="selected">
                                                        {window.location.pathname.toLowerCase().includes(item.link.toLowerCase()) ? <img src={item.activeIcon} style={sidebarStyles.sideImd} /> : <img src={item.icon} style={sidebarStyles.sideImd} />}
                                                        <span className={`navigation__links ${addClassIfArabic(this.props.i18n)}`} style={{ display: this.renderSidebarText() }}>
                                                            {item.label}
                                                        </span>
                                                    </ListItem>
                                                </NavLink>
                                            ))}
                                        </SidebarList>
                                    </div>
                                    <BoxPositionChange3 to="/Contactus">
                                        <Typography style={this.renderSideBarFooter()}>{this.props.t("layout_page.our_support_team_is_happy_to_help")}</Typography>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px", marginTop: "10px", marginBottom: "10px" }}>
                                            <div><img src={contact} alt="contact" style={{ width: "40.25px", height: "40px" }} /></div>
                                            <div style={this.state.expand ? sidebarStyles.dashboardEmpty : sidebarStyles.dashboardText}>{this.props.t("layout_page.contact_us")}</div>
                                        </div>
                                    </BoxPositionChange3>
                                </div>
                            </BoxPosition>
                        </Box> :
                        this.renderMobileSideBar()}
                    <div style={{ width: "100%", padding: "0 24px", background: "#f5f5f5", overflow: "auto", boxSizing: "border-box" }}>
                        <div>
                            <TopbarWrapper>
                                <div style={{ display: "flex", gap: "10px" }}>
                                    {window.innerWidth <= 980 && (
                                        <CustomIconButton onClick={this.toggleHamIcon}>
                                            <MenuIcon />
                                        </CustomIconButton>
                                    )}
                                    <span className="current-date">
                                    {this.props.t('dateFormat', { day, date, month, year })}
                                    </span>
                                </div>
                                <Box sx={{display: "flex",}} style={{gap:"3px",alignItems:"center"}}>
                                <button data-test-id="language-select" className="custom-language-switcher" onClick={() => this.updateLanguage(this.props.i18n.language == 'ar' ?"en":"ar")}>{this.props.i18n.language == 'ar' ? "English" : 'العربية'}</button>
                                    <ProfileSection
                                        handleHelpCenterNavigation={this.handleHelpCenterNavigation}
                                        handleLogoutModalOpen={this.handleLogoutModalOpen}
                                        handleSettingNavigation={this.handleSettingNavigation}
                                        handleViewProfile={this.handleViewProfile}
                                        handleWalletNavigation={this.handleWalletNavigation}
                                        profileData={this.state.profileData}
                                        showLogoutModal={this.state.logoutModal}
                                        showProfileTile={this.showProfileTile()}
                                        userType={this.showUseType()}
                                        walletBalance={this.state.walletBalance}
                                        data-test-id="ProfileSection"
                                        availableText={this.props.t('layout_page.available')}
                                    />
                                </Box>
                                    
                                    <LogoutModal showLogoutModal={this.state.logoutModal} logoutModalClose={this.handleLogoutClose} logoutUser={this.logoutUserHandler} />
                            </TopbarWrapper>
                            {this.props.isNotShowBorderBottom && <CustomDivider style={{ borderBottom: "1px solid #F0E5FF", width: "100%" }} />}
                            {this.props.children}
                        </div>
                    </div>
                </Box>
            </MainSectionWrapper>
        )
    }
    // Customizable Area End
}

// Customizable Area Start

const CustomDrawer = styled(Drawer)({
    "& .MuiDrawer-paper": {
        overflowY: "hidden"
    },
    "& .wrapper_container": {
        width: "100%",
        maxWidth: "288px",
        background: "#5F0AE5",
        height: "100%"
    },
    "& .sidebar_collapse_style": {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    "& .navigation_links": {
        flex: "1 1 auto",
        overflow: "hidden auto",
        scrollbarWidth: "none",
        margin: "1rem 0",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    }
});

export default (withTranslation()(Layout));

const MainSectionWrapper = styled("section")({
    width: "100%",
    background: "#F5F5F5",
    minHeight: "100vh",
    overflow: "auto",
    height: "100%",

    "& .main__container": {
        display: "flex",
        overflow: "hidden",
        height: "100%",
    },

    '& .custom-language-switcher': {
        cursor: 'pointer',
        fontSize: '18px',
        background: 'transparent',
        border: 'none',
        outline: 'none',
        color: '#390689',
        "@media (max-width: 600px)": {
        display: 'flex',
        }
    },

    "& .main__wrapper": {
        minHeight: "100vh",
        position: "relative",
        width: "100%",
        transition: "all 0.45s ease-in-out",
    },

    "& .sidebar__wrapper": {
        height: "100%",
        minHeight: "100%",
        background: "#5F0AE5",
        width: "100%",
        display: "flex",
        flexDirection: "column"
    },

    "& .navigation__links": {
        fontFamily: "Martian Mono",
        fontSize: "16px",
        fontWeight: 300,
        lineHeight: "19.2px",
        textAlign: "start",
        color: "#FFFFFF",
        "&.arabic-class":{
            wordSpacing:"-3px"
        }
    }
});
const BoxPosition = styled(Box)({
    position: "relative",
    height: "100%",
    "@media (max-width: 710px)": {
        position: "absolute",
        zIndex: 5,
    },

    "& .sidebar__sections": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flex: "1 1 auto",
        gap: "20px",
        overflow: "hidden auto",
        marginBottom: "1rem",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    }
});
const TopbarWrapper = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    top: 0,
    position: "relative",
    paddingTop: "20px",
    paddingBottom: "20px",
    boxSizing: "border-box",
    "& .current-date": {
        fontWeight: 400,
        fontFamily: "Open Sans",
        fontSize: "18px",
        color: "#390689",
        lineHeight: "24.51px",
    },
    "& .profileSection": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px"
    },
    "& .profileImage": {
        width: "40px",
        height: "40px",
        minWidth: "40px",
        borderRadius: "50%",
        objectFit: "cover",
        backgroundRepeat: "no-repeat"
    },
    "& .arrow": {
        cursor: "pointer"
    },
    "& .profileBox": {
        height: "auto",
        width: "302px",
        background: "white",
        position: "absolute",
        zIndex: 5,
        top: 75,
        border: "1px solid #E2E8F0",
        borderRadius: "40px",
        boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
        "@media (max-width: 480px)": {
            right: "0 !important",
            top: "100 !important",
        }
    },
    "& .secondaryProfileBox": {
        textAlign: "start",
        padding: "20px",
        boxSizing: "border-box",
    },
    "& .profileName": {
        fontWeight: 400,
        fontFamily: "Open Sans",
        fontSize: "18px",
        color: "#390689",
        "@media (max-width: 480px)": {
            fontSize: "16px !important"
        },
    },
    "& .avatarBox": {
        display: "flex",
        gap: "17px"
    },
    "& .avatarStyle": {
        width: "45px",
        cursor: "pointer",
        height: "45px",
        borderRadius: "50%",
        objectFit: "cover",
        objectPosition: "center",
        backgroundRepeat: "no-repeat"
    },
    "& .dividers": {
        background: "#0000001A"
    },
    "& .current-username": {
        margin: 0,
        fontFamily: "Martian Mono",
        fontSize: "18px",
        fontWeight: 500,
        lineHeight: "21.6px",
        color: "#390689",
    },
    "& .user-type": {
        color: "#390689",
        fontSize: "14px",
        fontFamily: "Open Sans",
        fontWeight: 400,
        margin: 0,
        "&.arabic-class":{
            textAlign:"right",
        }
    },
    "& .available-btn": {
        margin: "30px 0",
        display: "flex",
        alignItems: "center",
        gap: "5px",
        "& p": {
            fontFamily: "Open Sans",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "24px",
            textAlign: "start",
            color: "#390689",
            margin: 0,
        }
    },
    "& .profile-option-list": {
        cursor: "pointer",
        display: "flex",
        margin: 0,
        paddingInlineStart: 0,
        paddingInlineEnd:0,
        boxSizing: "border-box",
        flexDirection: "column",
        gap: "30px"
    },
    "& .option-list-item": {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        fontFamily: "Open Sans",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "29.5px",
        textAlign: "start",
        color: "#390689",
    },
    "& .subTOPmenuPdT": {
        cursor: "pointer",
        textAlign: "start",
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between',
        paddingTop: "0px",
    },
    '& .walletAmount': {
        fontWeight: 700,
        fontFamily: "Open Sans",
        fontSize: "16px",

    },
    "& .logoutBox": {
        padding: "20px 25px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        cursor: "pointer",
        boxSizing: "border-box"
    },
    "& .logoutBox p": {
        fontFamily: "Open Sans",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "29.5px",
        textAlign: "start",
        margin: 0,
        color: "#390689",
    },
    "& .marginRight": {
        marginInlineEnd: "10px"
    },
    "& .switch": {
        "& .MuiSwitch-thumb": {
            marginTop: "3px !important",
            marginInlineStart: "2px !important",
            width: "13px !important",
            height: "13px !important"
        },
        "& .MuiSwitch-root": {
            width: "58px !important",
            height: "42px !important"
        },
        "& .MuiSwitch-track": {
            borderRadius: "50px !important"
        },
        "& .MuiSwitch-colorPrimary.Mui-checked": {
            color: "#7F3BE9"
        }
    }
});
const BoxPositionChange3 = styled(NavLink)({
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    letterSpacing: 0,
    textAlign: "center",
    color: "#FFF",
    textDecoration: "none",
    flex: "0 0 auto",
    '@media (max-width: 710px)': {
        position: "unset"
    },
});
const SidebarList = styled(List)({
    "& .selected": {
        gap: "15px"
    },
    "& .active .selected": {
        background: "#FFF",
        "& .navigation__links": {
            fontWeight: 500,
            color: "#390689",
        }
    },
    "& .active .selected .dashboardEmpty ": {
        color: "rgba(57, 6, 137, 1)"
    },
    "& .dashboardEmpty.arabic-class":{
        wordSpacing:"-3px"
    }
});
export const CustomDivider = styled(Divider)({
    "& .MuiDivider-root": {
        height: "0px !important"
    }
});
const CustomIconButton = styled(IconButton)({
    padding: "unset",
    boxSizing: "border-box",
    color: "#390689",
    "&:hover": {
        backgroundColor: "unset",
    }
});
const sidebarStyles: any = {
    showSidebarContent: {
        fontSize: "16px",
        fontFamily: "Open sans",
        fontWeight: 700,
        lineHeight: "20px",
        margin: "1rem"
    } as React.CSSProperties,
    hideSidebarContent: {
        display: "none",
    } as React.CSSProperties,
    dashboardEmpty: {
        fontSize: "15px",
        display: "contents"
    } as React.CSSProperties,
    dashboardText: {
        display: "none"
    } as React.CSSProperties,
    margin_bottom: {
        padding: "13px 20px",
        color: "#FFF",
        boxSizing: "border-box",
    } as React.CSSProperties,
    sideImd: {
        width: "34px",
        height: "34px",
    } as React.CSSProperties,
};

const LanguageSelect = styled(Select)({
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiSelect-icon': {
      display: 'none',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent !important'
    }
  });
// Customizable Area End
