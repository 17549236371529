import * as Yup from "yup";

const SUPPORTED_FORMATS_CERTIFICATION = [
  "application/msword",
  "text/plain",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "text/rtf",
  "application/pdf",
  "application/rtf",
];

const SUPPORTED_EXTENSIONS_CERTIFICATION = ["docx", "pdf", "rtf", "txt", "doc"];
const FILE_SIZE_CERTIFICATION = 2 * 1024 * 1024;

export const editBankFormValidation = Yup.object().shape({
  selected_bank: Yup.object()
    .shape({
      label: Yup.string().required("coach_view_profile.please_select_bank"),
      value: Yup.string().required("coach_view_profile.please_select_bank"),
    })
    .required("coach_view_profile.please_select_bank"),
  bank_name: Yup.string()
    .trim()
    .required("coach_pre_assessment_test.bank_name_is_required"),
  full_name: Yup.string()
    .matches(
      /^[A-Z][a-zA-Z]*(?: [A-Z][a-zA-Z]*)*$/,
      "coach_view_profile.full_name_must_start_with_a_capital_letter_and_contain_only_letters_and_single_spaces"
    )
    .trim()
    .required("coach_view_profile.full_name_is_required"),
  iban: Yup.string()
    .when("selected_bank.value", {
      is: "international_bank",
      then: Yup.string()
        .trim()
        .matches(
          /^[A-Z]{2}\w{13,32}$/,
          "coach_view_profile.iban_for_international_banks_must_be_15_to_34_characters_long_and_start_with_2_letters"
        ),
      otherwise: Yup.string().matches(
        /^SA\d{22}$/,
        "coach_view_profile.iban_must_start_with_sa_and_be_followed_by_22_digits"
      ),
    })
    .trim()
    .required("coach_view_profile.iban_is_required"),
  account_number: Yup.string().when("selected_bank.value", {
    is: "saudi_bank",
    then: Yup.string()
      .trim()
      .matches(
        /^\d{18}$/,
        "coach_view_profile.account_number_must_be_exactly_18_digits"
      )
      .required("coach_view_profile.account_number_is_required"),
    otherwise: Yup.string().notRequired(),
  }),
  city: Yup.string().when("selected_bank.value", {
    is: "saudi_bank",
    then: Yup.string().trim().required("coach_view_profile.city_is_required"),
    otherwise: Yup.string().notRequired(),
  }),
  address: Yup.string().when("selected_bank.value", {
    is: "saudi_bank",
    then: Yup.string().trim(),
    otherwise: Yup.string().notRequired(),
  }),
  building_number: Yup.string().when("selected_bank.value", {
    is: "saudi_bank",
    then: Yup.string().trim(),
    otherwise: Yup.string().notRequired(),
  }),
  nick_name: Yup.string().when("selected_bank.value", {
    is: "international_bank",
    then: Yup.string()
      .trim()
      .required("coach_view_profile.nick_name_is_required"),
    otherwise: Yup.string().notRequired(),
  }),
  bank_country: Yup.string().when("selected_bank.value", {
    is: "international_bank",
    then: Yup.string()
      .trim()
      .required("coach_view_profile.bank_country_is_required"),
    otherwise: Yup.string().notRequired(),
  }),
  bank_city_branch: Yup.string().when("selected_bank.value", {
    is: "international_bank",
    then: Yup.string()
      .trim()
      .required("coach_view_profile.bank_country_branch_is_required"),
    otherwise: Yup.string().notRequired(),
  }),
  bank_address: Yup.string().when("selected_bank.value", {
    is: "international_bank",
    then: Yup.string()
      .trim()
      .required("coach_view_profile.bank_address_is_required"),
    otherwise: Yup.string().notRequired(),
  }),
  currency: Yup.string().when("selected_bank.value", {
    is: "international_bank",
    then: Yup.string()
      .trim()
      .required("coach_view_profile.currency_is_required"),
    otherwise: Yup.string().notRequired(),
  }),
  swift_code: Yup.string().when("selected_bank.value", {
    is: "international_bank",
    then: Yup.string()
      .trim()
      .min(8, "coach_view_profile.swift_code_must_be_8_11_characters")
      .max(11, "coach_view_profile.swift_code_must_be_8_11_characters")
      .required("coach_view_profile.swift_code_is_required"),
    otherwise: Yup.string().notRequired(),
  }),
  your_address: Yup.string().when("selected_bank.value", {
    is: "international_bank",
    then: Yup.string()
      .trim()
      .required("coach_view_profile.your_address_is_required"),
    otherwise: Yup.string().notRequired(),
  }),
  bank_account_doc: Yup.mixed()
    .test("is-string-or-file", "Invalid value", function (value) {
      return (
        typeof value === "string" || value === null || value instanceof File
      );
    })
    .test(
      "fileType",
      "coach_view_profile.invalid_file_format_please_select_docx__doc__pdf__rtf__txt",
      function (value) {
        if (value instanceof File) {
          return SUPPORTED_FORMATS_CERTIFICATION.includes(value.type);
        }
        return true;
      }
    )
    .test(
      "fileExtension",
      "coach_view_profile.invalid_file_format_please_select_docx__doc__pdf__rtf__txt",
      function (value) {
        if (value && typeof value !== "string") {
          const fileExtension = value.name.split(".").pop().toLowerCase();
          return SUPPORTED_EXTENSIONS_CERTIFICATION.includes(fileExtension);
        }
        return true;
      }
    )
    .test(
      "fileSize",
      "coach_view_profile.file_size_exceeds_the_maximum_limit_please_upload_a_file_smaller_than_2_mb",
      function (value) {
        if (value instanceof File) {
          return value.size <= FILE_SIZE_CERTIFICATION;
        }
        return true;
      }
    )
    .required("coach_view_profile.bank_account_doc_is_required"),
});

export const assignAssessmentSchema = Yup.object().shape({
  clientName: Yup.string()
    .trim()
    .required("coaching_resources_coach.this_field_is_required"),
  assessmentName: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required("coaching_resources_coach.this_field_is_required"),
  customAssessmentName: Yup.string()
    .trim()
    .when("assessmentName.label", {
      is: (label: string) => label?.toLowerCase() === "other",
      then: (schema: { required: (arg0: string) => any; }) =>
        schema.required("coaching_resources_coach.this_field_is_required"),
      otherwise: (schema: { notRequired: () => any; }) => schema.notRequired(),
    }),
  startDate: Yup.date()
    .nullable()
    .transform((value, originalValue) =>
      originalValue
        ? new Date(new Date(originalValue).setHours(0, 0, 0, 0))
        : null
    )
    .min(
      new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
      "sessions.start_date_error"
    )
    .required("coaching_resources_coach.this_field_is_required"),
  dueDate: Yup.date()
    .nullable()
    .transform((value, originalValue) =>
      originalValue
        ? new Date(new Date(originalValue).setHours(0, 0, 0, 0))
        : null
    )
    .min(Yup.ref("startDate"), "sessions.due_date_error")
    .required("coaching_resources_coach.this_field_is_required"),
  description: Yup.string()
    .required("coaching_resources_coach.this_field_is_required")
    .max(500, "coach_client_rest.max_500_char_allowed"),
});

const SUPPORTED_FORMATS_RESULT = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.oasis.opendocument.presentation",
  "application/vnd.ms-powerpoint",
];

const SUPPORTED_EXTENSIONS_RESULT = [
  "pdf",
  "doc",
  "docx",
  "ppt",
  "pptx",
  "odp",
];

export const uploadAssessmentSchema = Yup.object().shape({
  result: Yup.mixed()
    .required("Result is required")
    .test(
      "fileType",
      "coach_view_profile.invalid_file_format_please_select_docx__doc__pdf__ppt__pptx",
      (value) => value && SUPPORTED_FORMATS_RESULT.includes(value.type)
    )
    .test(
      "fileExtension",
      "coach_view_profile.invalid_file_format_please_select_docx__doc__pdf__ppt__pptx",
      (value) => {
        if (value) {
          const fileExtension = value.name.split(".").pop().toLowerCase();
          return SUPPORTED_EXTENSIONS_RESULT.includes(fileExtension);
        }
        return true;
      }
    )
    .test(
      "fileSize",
      "coach_view_profile.file_size_exceeds_the_maximum_limit_please_upload_a_file_smaller_than_2_mb",
      (value) => value && value.size <= FILE_SIZE_CERTIFICATION
    ),
});

export const getValidationSchema = (minDays = 1, maxDays = 45) => {
  return Yup.object().shape({
    startDate: Yup.date()
      .nullable()
      .min(new Date().toISOString().split("T")[0], "sessions.start_date_error")
      .required("sessions.start_date_required"),
    endDate: Yup.date()
      .required("sessions.end_date_is_required")
      .nullable()
      .min(
        Yup.ref("startDate"),
        `sessions.end_date_must_be_at_least_1_days_after_start_date`
      )
      .test(
        "min-days",
        `sessions.end_date_must_be_at_least_1_days_after_start_date`,
        function (value) {
          const { startDate } = this.parent;
          if (!startDate || !value) return true;
          const minDate = new Date(startDate);
          minDate.setDate(minDate.getDate() + minDays);
          const adjustedMinDate = new Date(minDate.setHours(0, 0, 0, 0));
          const endDate = new Date(value.setHours(0, 0, 0, 0));
          return endDate >= adjustedMinDate;
        }
      )
      .test(
        "max-days",
        `sessions.end_date_must_not_be_more_than_45_days_after_start_date`,
        function (value) {
          const { startDate } = this.parent;
          if (!startDate || !value) return true;
          const maxDate = new Date(startDate);
          maxDate.setDate(maxDate.getDate() + maxDays);
          const adjustedMaxDate = new Date(maxDate.setHours(0, 0, 0, 0));
          const endDate = new Date(value.setHours(0, 0, 0, 0));
          return endDate <= adjustedMaxDate;
        }
      ),
  });
};

export const validationStartEndDateSchema = Yup.object().shape({
  startDate: Yup.date().nullable().required("sessions.start_date_required"),
  endDate: Yup.date()
    .required("sessions.end_date_is_required")
    .nullable()
    .min(
      Yup.ref("startDate"),
      `sessions.end_date_must_be_at_least_1_days_after_start_date`
    ),
});
