import { i18n, TFunction } from "i18next";
import React, { useEffect } from "react";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import moment from "moment";
import i18nSetup from "../../framework/src/i18config";

export const apiCall = async (data: {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: any;
  token?: string;
}) => {
  const { contentType, method, endPoint, body, token } = data;

  const header: any = {
    token: token ? token : localStorage.getItem("authToken"),
  };
  if (contentType) {
    header["content-type"] = contentType;
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );
  body &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};

export const mockAPICall = (
  instance: any,
  apiCallID: string,
  apiData: object
) => {
  const msgSucessRestAPI = new Message(
    getName(MessageEnum.RestAPIResponceMessage)
  );
  msgSucessRestAPI.addData(
    getName(MessageEnum.RestAPIResponceDataMessage),
    msgSucessRestAPI.messageId
  );
  msgSucessRestAPI.addData(
    getName(MessageEnum.RestAPIResponceSuccessMessage),
    apiData
  );
  instance[apiCallID] = msgSucessRestAPI.messageId;
  runEngine.sendMessage("Unit Test", msgSucessRestAPI);
};

export interface RecentPackages {
  id: string;
  type: string;
  attributes: SubscriptionAttributes;
}

interface SubscriptionAttributes {
  id: number;
  account_id: number;
  coach_id: number;
  package_id: number;
  start_date: string;
  end_date: string;
  sessions_remaining: number;
  no_shows_remaining: number;
  freeze_times_allowed: number;
  order_id: string;
  freeze_days_allowed: number;
  freeze_times_used: number;
  is_frozen: boolean;
  freeze_start_date: string | null;
  status: string;
  final_status: string;
  final_price: number;
  includes_service: string[];
  includes_service_ar: string[];
  package_progress: number;
  payment_details: {
    wallet_used: number;
    discount: number;
    amount_paid: string;
    invoice_url: null | string;
    coupon: {
      id: number;
      max_use_per_user: number;
      coupon_description: string;
      coupon_name: string;
      start_date: string;
      coupon_type: string;
      maximum_usage_limit: string;
      end_date: string;
      created_at: string;
      coupon_code: string;
      usage_left: number;
      updated_at: string;
      flat_discount: number | null;
      discount_percentage: number;
    };
  };
  package: PackageData;
  coach: CoachData;
}

interface PackageData {
  data: PackageAttributes;
}

interface PackageAttributes {
  id: string;
  type: string;
  attributes: {
    includes_service_ar: any;
    id: number;
    package_name: string;
    package_name_ar: string;
    duration: string;
    start_date: string;
    end_date: string;
    price: number;
    discount_price: number;
    no_of_session: number;
    no_of_freezing: string;
    no_of_no_show: string;
    note: string;
    created_at: string;
    freeze_allowed: number;
    freeze_days_allowed: number;
    updated_at: string;
    includes_service: any[];
    final_price: number;
  };
}

interface CoachData {
  data: CoachAttributes;
}

interface CoachAttributes {
  id: string;
  type: string;
  attributes: {
    full_name: string;
    user_bio: string;
    full_phone_number: string;
    email: string;
    gender: string;
    coach_languages: string[];
    country_name: string;
    dob: string;
    city: string;
    profile_pic: string;
    icebreaker_answers: {
      describe_coaching_style_in_3_words: string;
      recently_did_or_read: string;
      accoplishment: string;
    };
    social_profile: {
      facebook_url: string;
      linkedin_url: string;
      twitter_url: string;
      instagram_url: string;
    };
    availability_status: boolean;
    chemistry_call_charge: number;
  };
}

export const redirectToPage = (navigation: any, path: string) => {
  navigation.navigate(path);
};

export const checkTimeDifference = (event_time: string) => {
  const currentTimeUTC = moment().utc();

  const eventTimeUTC = moment.utc(event_time);

  const timeDifference = eventTimeUTC.diff(currentTimeUTC, "hours");

  return timeDifference < 48 ? true : false;
};

export const calculateCalculateNo = (
  no_of_session: number,
  sessions_remaining: number
) => {
  return no_of_session - sessions_remaining;
};

export const formatNumber = (num: number) => {
  if (Number.isInteger(num)) {
    return num.toString();
  } else {
    return num ? num.toFixed(2) : num;
  }
};
export const calculatePercentage = (
  no_of_session: number,
  sessions_remaining: number
) => {
  const completedSession = calculateCalculateNo(
    no_of_session,
    sessions_remaining
  );
  return formatNumber((completedSession / no_of_session) * 100);
};

export const packageStatus = (status: string) => {
  if (status === "completed") {
    return {
      backgroundColor: "#6200EA",
    };
  } else if (status === "active") {
    return { backgroundColor: "#9F6CEF" };
  } else {
    return {
      backgroundColor: "white",
      border: "1px solid #390689",
      color: " #390689",
    };
  }
};

export const renderBookSessionErrorForClient = (
  coachDetails: {
    id: number | string;
    full_name: string;
    value?: string;
    email: string;
    url: string;
    has_package: boolean;
    is_frozen: boolean;
  },
  t: TFunction
) => {
  if (!coachDetails.has_package) {
    return t("coach_dashboard.no_active_package_error");
  }
  if (coachDetails.is_frozen) {
    return t("coach_dashboard.your_package_frozen_error");
  }
  return "";
};

export const renderBookSessionErrorForCoach = (
  clientDetails: {
    id: number | string;
    full_name: string;
    value?: string;
    email: string;
    has_package: boolean;
    is_frozen: boolean;
    chemistry_session_calendly_url: string;
  },
  t: TFunction
) => {
  if (!clientDetails.has_package) {
    return t("coach_dashboard.no_active_package_error");
  }
  if (clientDetails.is_frozen) {
    return t("coach_dashboard.client_frozen_package_error");
  }
  return "";
};

export const reasonForDeactivation = [
  "coach_settings.privacy_concerns",
  "coach_settings.created_another_account",
  "coach_settings.i_am_not_using_system_anymore",
  "coach_settings.i_found_better_coaching_management_platform",
  "coach_settings.i_didnt_receive_enough_clients",
  "coach_settings.i_dont_find_coachy_useful",
  "coach_settings.my_experience_was_not_as_expected",
  "coach_settings.it_is_temporary__i_will_be_back",
  "coach_settings.something_else",
];

export const COACH_DEACTIVATE_REASONS = [
  "Privacy concerns",
  "Created another account",
  "I am not using system anymore",
  "I found better coaching management platform",
  "I didn't receive enough clients",
  "I don't find COACHY useful",
  "My experience was not as expected",
  "It is temporary, I will be back",
  "Something else",
];

export const checkDirection = (i18n: i18n) => {
  return i18n.language === "ar" ? "rtl" : "ltr";
};

export const addClassIfArabic = (i18n: i18n) => {
  return i18n.language === "ar" ? "arabic-class" : "english-class";
};

export const mobileSideMenuDirection = (i18n: i18n) => {
  return i18n.language == "ar" ? "right" : "left";
};

export const tableDirection = (i18n: i18n, direction: "left" | "right") => {
  if (i18n.language === "ar" && direction === "right") {
    return "left";
  } else if (i18n.language === "ar" && direction === "left") {
    return "right";
  } else {
    return direction;
  }
};

export const changeSeparatorDirection = (i18n: i18n) => {
  return i18n.language === "ar" ? "180deg" : "0";
};

export const oppositeDirection = (i18n: i18n) => {
  return i18n.language === "ar" ? "right" : "left";
};

export const flexDirectionForAr = (i18n: i18n) => {
  return i18n.language === "ar" ? "row-reverse" : "row";
};

export const useOnClickOutside = (ref: any, handler: any) => {
  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

export const showArabicText = (text: string, arabicText: string) => {
  return i18nSetup.language === "ar" ? arabicText : text;
};

export const returnArabicArray = (text: string[], arabicText: string[]) => {
  return i18nSetup.language === "ar" ? arabicText : text;
};

export const convertDateToArabic = (
  dateString: string,
  type: string,
  t: any
) => {
  if (i18nSetup.language === "en") return dateString;

  const dateRegex = /(\d{1,2})\s([A-Za-z]+)/; // Eg: 10 Jan
  const dateIntervalRegex = /(\d{1,2})\s([A-Za-z]+)-(\d{1,2})\s([A-Za-z]+)/; // Eg: 10 Jan-12 Jan
  const hyphenDateRegex = /(\d{1,2})-([A-Za-z]+)-(\d{4})/; // Eg: 18-Mar-2025
  const withoutHyphenDateRegex = /(\d{1,2})\s([A-Za-z]+)\s(\d{4})/; // Eg: 18 Mar 2025
  const dateRegexddmmm = /(\d{1,2})-([A-Za-z]+)/; // Eg: 10-Jan

  const shortToFullMonth: Record<string, string> = {
    Jan: "January",
    Feb: "February",
    Mar: "March",
    Apr: "April",
    May: "May",
    Jun: "June",
    Jul: "July",
    Aug: "August",
    Sep: "September",
    Oct: "October",
    Nov: "November",
    Dec: "December",
  };

  if (type === "date") {
    const match = dateString.match(dateRegex);

    if (match) {
      let [, day1, month1] = match;

      // Convert short names to full names
      month1 = shortToFullMonth[month1] || month1;

      const month1String = "monthNames." + month1;

      const arabicMonth1 = t(month1String);

      return `${day1} ${arabicMonth1}`;
    } else {
      return "";
    }
  } else if (type === "dateInterval") {
    const match = dateString.match(dateIntervalRegex);

    if (match) {
      let [, day1, month1, day2, month2] = match;

      // Convert short names to full names
      month1 = shortToFullMonth[month1] || month1;
      month2 = shortToFullMonth[month2] || month2;

      const month1String = "monthNames." + month1;
      const month2String = "monthNames." + month2;

      const arabicMonth1 = t(month1String);
      const arabicMonth2 = t(month2String);

      return `${day1} ${arabicMonth1} - ${day2} ${arabicMonth2}`;
    }
  } else if (type === "hyphenDate") {
    const match = dateString.match(hyphenDateRegex);
    console.log(match);

    if (match) {
      let [, day1, month1, year1] = match;

      // Convert short names to full names
      month1 = shortToFullMonth[month1] || month1;

      const month1String = "monthNames." + month1;

      const arabicMonth1 = t(month1String);
    
      return `${day1} - ${arabicMonth1} - ${year1}`;
    } else{
      return "";
    }
  } else if (type === "withoutHyphenDate") {
    const match = dateString.match(withoutHyphenDateRegex);
    console.log(match);

    if (match) {
      let [, day1, month1, year1] = match;

      // Convert short names to full names
      month1 = shortToFullMonth[month1] || month1;

      const month1String = "monthNames." + month1;

      const arabicMonth1 = t(month1String);

      return `${day1} ${arabicMonth1} ${year1}`;
    } else {
      return "";
    }
  }

  else if(type === "dateddmmm") {
    const match = dateString.match(dateRegexddmmm);

    if (match){
      let [, day1, month1] = match;

      // Convert short names to full names
      month1 = shortToFullMonth[month1] || month1;
      
      const month1String = "monthNames."+month1;

      const arabicMonth1 = t(month1String);
    
      return `${day1} - ${arabicMonth1}`;
    } else{
      return "";
    }
  }
  
}

export interface ProfileAccount {
  id: string;
  type: string;
  attributes: Attributes;
}

interface Attributes {
  id: number;
  full_name: string;
  user_type: string;
  availability_status: boolean;
  gender: string;
  country_name: string;
  country_code: number;
  city: string;
  country_flag: string;
  phone_number: number;
  full_phone_number: string;
  email: string;
  coach_profile_status: any;
  coach_languages: string[];
  user_bio: string;
  optional_email_enabled: boolean;
  date_of_birth: Date | null;
  activated: boolean;
  profile_pic: string;
  introduction_video: any;
  preferred_coaching_language: string[];
  wallet_balance: number;
  chemistry_call_charge: number;
  street_address: string;
  state: string;
  zip_code: string;
}

export const getDateRange = (range: string) => {
  const end_date = moment().format("YYYY-MM-DD");
  let start_date = "";

  switch (range) {
    case "last_7_days":
      start_date = moment().subtract(6, "days").format("YYYY-MM-DD");
      break;
    case "last_14_days":
      start_date = moment().subtract(13, "days").format("YYYY-MM-DD");
      break;
    case "last_30_days":
      start_date = moment().subtract(29, "days").format("YYYY-MM-DD");
      break;
    default:
      start_date = moment().format("YYYY-MM-DD");
      break;
  }

  return { start_date, end_date };
};

export const convertSarToDollar = (
  currency: string,
  amount: string | number | null
) => {
  if (currency === "usd") {
    return (Number(amount) / 3.75).toFixed(1);
  }
  return amount;
};

export const showCurrencyText = (currency: string) => {
  if (currency === "usd") {
    return i18nSetup.t("revenue_financial.usd");
  }
  return i18nSetup.t("revenue_financial.sar");
};

export const showIfTextAvailable = (text:string,arabic_text:string)=>{
  if(text){
   return showArabicText(text,arabic_text);
  }
  return i18nSetup.t("plans_page.no")
}