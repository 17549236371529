import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area Start
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { apiCall } from "../../../components/src/utils";
export const configJSON = require("./config");
const navigation = require("react-navigation");
import { TFunction } from "i18next";

export interface ProfileData {
    id: string;
    type: string;
    attributes: {
        id: number;
        full_name: string;
        user_type: string;
        availability_status: boolean;
        gender: string;
        country_name: string;
        country_code: number;
        city: string;
        country_flag: string;
        phone_number: number;
        full_phone_number: string;
        email: string;
        coach_profile_status: null | string;
        coach_languages: string[];
        user_bio: string;
        date_of_birth: string;
        activated: boolean;
        profile_pic: string;
        introduction_video: null | string;
        preferred_coaching_language: string[];
        wallet_balance: number;
    };
}
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: typeof navigation;
    id: string;
    isNotShowBorderBottom: boolean;
    t:TFunction;
    i18n:any;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    sidebarExpanded: boolean;
    logoutModal: boolean;
    openProfileModal: boolean;
    profileData: ProfileData;
    isLoading: boolean;
    isCollapsed: boolean;
    showSidebar: boolean;
    isZindexApply: boolean;
    expand: boolean;
    toggleDrawer: boolean;
    isChecked: boolean;
    userType: string;
    walletBalance: {
        updated_wallet_amount: number,
        wallet_used: number
    },
    language: string;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class LayoutController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getProfileDetailsApiCallId: string = "";
    getLatestWalletBalanceApiCallId: string = "";
    setUserPreferredLanguageApiCallId: string = "";
    // Customizable Area End    

    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationPropsMessage),
        ];
        this.receive = this.receive.bind(this);

        this.state = {
            sidebarExpanded: false,
            logoutModal: false,
            openProfileModal: false,
            profileData: {
                id: "",
                type: "",
                attributes: {
                    id: 0,
                    full_name: "",
                    user_type: "",
                    availability_status: false,
                    gender: "",
                    country_name: "",
                    country_code: 0,
                    city: "",
                    country_flag: "",
                    phone_number: 0,
                    full_phone_number: "",
                    email: "",
                    coach_profile_status: null,
                    coach_languages: [],
                    user_bio: "",
                    date_of_birth: "",
                    activated: false,
                    profile_pic: "",
                    introduction_video: null,
                    preferred_coaching_language: [],
                    wallet_balance: 0
                }
            },
            isCollapsed: false,
            showSidebar: window.innerWidth >= 980,
            isZindexApply: false,
            isLoading: false,
            expand: false,
            toggleDrawer: false,
            isChecked: true,
            userType: "",
            walletBalance: {
                updated_wallet_amount: 0,
                wallet_used: 0
            },
            language:this.props.i18n.language??"en"
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getProfileDetails();
        let userType = await getStorageData("userType");
        this.setState({ userType });
        if (window.innerWidth <= 980) {
          this.handleResize();
        }
        window.addEventListener("resize", this.handleResize);
        window.addEventListener("start", this.updateProfile);
        window.addEventListener("update_wallet_balance", this.updateWalletBalances);
    }

    async componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        window.removeEventListener("start", this.updateProfile);
        window.removeEventListener("update_wallet_balance", this.updateWalletBalances);
    }

    updateProfile = (event: any) => {
        if(event?.detail?.updateProfile) {
            this.getProfileDetails();
        }
    }

    updateWalletBalances = (event: any) => {
        if(event?.detail?.updateWalletBalance) {
            this.updateWalletBalance(event?.detail?.updateWalletBalance);
        }
    }

    toggleDrawerHandler = () => {
        this.setState({ toggleDrawer: false })
    }

    handleResize = () => {
        this.setState({
            isCollapsed: window.innerWidth >= 980,
            showSidebar: window.innerWidth >= 980
        });
    };

    toggleHamIcon = () => {
        this.setState(prevState => ({
            // showSidebar: !prevState.showSidebar,
            toggleDrawer: true,
            isZindexApply: !prevState.isZindexApply
        }));
    }

    handleMouseEnter = () => {
        this.setState({ expand: true });
    };

    handleMouseLeave = () => {
        this.setState({ expand: false });
    };

    handleOpenProfile = () => {
        this.setState({
            openProfileModal: !this.state.openProfileModal,
            isChecked: true
        })
    }

    logoutUserHandler = async () => {
        this.setState({ logoutModal: false });
        await removeStorageData('token');
        await removeStorageData("userType");
        const landingPage: Message = new Message(getName(MessageEnum.NavigationMessage));
        landingPage.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
        landingPage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(landingPage);
    }

    handleLogoutModalOpen = () => {
        this.setState({ logoutModal: true });
    }

    handleLogoutClose = () => {
        this.setState({ logoutModal: false });
    }

    handleSettingNavigation = () => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), this.state.userType == "coach" ? "CoachSettings" : "ClientSetting");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    };
    handleWalletNavigation = () => {
        const message: Message = 
        new Message(
          getName(MessageEnum.NavigationMessage));
        message.addData(
          getName(
            MessageEnum.NavigationTargetMessage), "Cfwallet44"
            );
        message.addData(
          getName(MessageEnum.NavigationPropsMessage
            ), this.props
            );
        this.send(message);
      };
    handleHelpCenterNavigation = () => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "HelpCenter");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    };

    handleViewProfile = () => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), this.state.userType == "coach" ? "CoachViewProfile" : "Profile");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    };

    updateWalletBalance = async (wallet_credit_discount: string) => {
        const token = await getStorageData("token");
        this.setState({ isLoading: true });
        this.getLatestWalletBalanceApiCallId = await apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: `bx_block_cfhyperpaypaymentgateway/payments/check_wallet?wallet=${wallet_credit_discount}`,
            token
        })
    }

    getProfileDetails = async () => {
        this.setState({ isLoading: true });
        const token = await getStorageData("token");

        const headers = {
            token,
            "content-type": configJSON.validationApiContentType
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getProfileDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "account_block/accounts/get_profile"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
            return;
        }

        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        if (this.getProfileDetailsApiCallId === apiRequestCallId) {
            this.handleProfileData(responseJson);
        } else if (this.getLatestWalletBalanceApiCallId === apiRequestCallId) {
            this.handleWalletBalance(responseJson);
        } else if (this.setUserPreferredLanguageApiCallId === apiRequestCallId) {
            this.handleLanguagePreference(responseJson);
        }
    }

    handleProfileData(responseJson: any) {
        if(responseJson.error || responseJson.errors) {
            localStorage.clear();
            this.props.navigation.navigate("EmailAccountLoginBlock");
        }
        if (responseJson?.data) {
            this.setState({ profileData: responseJson.data, isLoading: false });
        }
    }

    handleWalletBalance(responseJson: any) {
        if (responseJson && !responseJson.error) {
            this.setState({ walletBalance: responseJson, isLoading: false });
        }
    }

    handleLanguagePreference(responseJson: any) {
        if (!responseJson.errors && !responseJson.error) {
            location.reload();
        }
    }

    toggleChecked = () => {
        this.setState((prevState)=>({
            isChecked: !prevState.isChecked
        }));
    };

    updateLanguage = async (value: string) => {
        localStorage.setItem("language", value);
        this.props.i18n.changeLanguage(value);
        this.setState({ isLoading: true, language: value });
        const token = await getStorageData("token");
        const headers = { token, "content-type": configJSON.validationApiContentType };
        const body = { language: value }
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.setUserPreferredLanguageApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts/update_language");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}