import React, { useEffect, useRef, useState } from "react";
import { styled, IconButton } from "@material-ui/core";
import { getStorageData } from "../../framework/src/Utilities";
import { addClassIfArabic } from "./utils";
import { useTranslation } from "react-i18next";

const useOnClickOutside = (ref: any, handler: any) => {
  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("touchstart", listener);
    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("touchstart", listener);
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};

interface IThreeDotProps {
  dropDownItemList: { itemText: string; itemOnClick: () => void }[];
  index: number;
  dataLength: number;
  maxItem: number;
  mobileMaxItem: number;
}

const ThreeDotDropDown: React.FC<IThreeDotProps> = ({
  dropDownItemList,
  index,
  dataLength,
  maxItem,
  mobileMaxItem,
}) => {
  const {i18n} = useTranslation();
  const dropdownRef = useRef(null);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [language, setLanguage] = useState("");
  const isUp =
    dataLength > (window.innerWidth < 959.96 ? mobileMaxItem : maxItem) &&
    (index == dataLength - 1 || index == dataLength - 2);

  useOnClickOutside(dropdownRef, () => setDropDownOpen(false));

  useEffect(() => {
    const firstDropDownItem = document.querySelectorAll(".dropdown-text");
    const dropDownIndex = !isUp ? 0 : firstDropDownItem.length - 1;
    firstDropDownItem[dropDownIndex]?.addEventListener("mouseout", mouseOut);
    firstDropDownItem[dropDownIndex]?.addEventListener("mouseover", mouseOver);
    return () => {
      firstDropDownItem[dropDownIndex]?.removeEventListener(
        "mouseover",
        mouseOver
      );
      firstDropDownItem[dropDownIndex]?.removeEventListener(
        "mouseout",
        mouseOut
      );
    };
  }, [dropDownOpen]);

  useEffect(() => {
    const fetchData = async () => {
      const language = await getStorageData("language");
      setLanguage(language)
    };

    fetchData();
    
  }, []);

  const mouseOver = () => {
    const svgIcon = document.querySelector(".triangle-svg") as HTMLElement;
    svgIcon.style.stroke = "#E2E8F0";
    svgIcon.style.fill = "#5F0AE5";
  };

  const mouseOut = () => {
    const svgIcon = document.querySelector(".triangle-svg") as HTMLElement;
    svgIcon.style.stroke = "#E2E8F0";
    svgIcon.style.fill = "#FFFFFF";
  };

  return (
    <DropdownWrapper ref={dropdownRef} >
      <IconButton style={{ padding: 0 }}>
        <div
          className="drop-down-button"
          onClick={() => {
            setDropDownOpen(!dropDownOpen);
          }}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              fill="#475569"
              d="M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM12 16C10.9 16 10 16.9 10 18C10 19.1 10.9 20 12 20C13.1 20 14 19.1 14 18C14 16.9 13.1 16 12 16Z"
            />
          </svg>
        </div>
      </IconButton>
      {dropDownOpen && (
        <div
          className="dropdown-container"
          style={{
            top: isUp ? "unset" : "34px",
            bottom: isUp ? "34px" : "unset",
            ...(language === 'en' ? { right: '-8px' } : { left: '-7px' }),
          }}
        >
          <div className="dropdown-text-container">
            <div className={isUp ? "triangle-down" : "triangle"}
              style={{
                ...(language === 'en' ? { right: '10px' } : { left: '10px' }),
              }}
            >
              <svg
                viewBox="0 0 24 13"
                className={"triangle-svg"}
                height="13"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.7097 0.852168C11.2679 0.382611 12.0829 0.382611 12.6411 0.852168L22.3125 8.98854C23.3834 9.88947 22.7463 11.6364 21.3468 11.6364H2.00399C0.604531 11.6364 -0.0325567 9.88947 1.03834 8.98854L10.7097 0.852168Z" />
              </svg>
            </div>
            {dropDownItemList.map((data, index) => (
              <div
                key={index}
                className={`dropdown-text ${addClassIfArabic(i18n)}`}
                onClick={() => {
                  data.itemOnClick();
                  setDropDownOpen(false);
                }}
              >
                {data.itemText}
              </div>
            ))}
          </div>
        </div>
      )}
    </DropdownWrapper>
  );
};

export default ThreeDotDropDown;

const DropdownWrapper = styled("div")({
  position: "relative",
  width: "max-content",
  "& svg": {
    height: "24px",
    width: "24px",
    cursor: "pointer",
  },
  "& .dropdown-container": {
    position: "absolute",
    minWidth: "182px",
    width: "fit-content",
    borderRadius: "8px",
    boxSizing: "border-box",
    border: "1px solid #E2E8F0",
    backgroundColor: "#fff",
    zIndex: 1,
  },
  "& .triangle-down": {
    bottom: "-14px",
    transform: "rotate(180deg)",
    zIndex: 0,
    position: "absolute",
  },
  "& .drop-down-button": {
    width: "32px",
    height: "32px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  "& .dropdown-text-container": {
    textAlign: "left",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  "& .dropdown-text": {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "600",
    color: "#390689",
    textAlign: "left",
    lineHeight: "22px",
    cursor: "pointer",
    padding: "16px 12px 16px 20px",
    whiteSpace: "nowrap",
    backgroundColor: "#fff",
    transition: "0.3s ease-in",
    "&.arabic-class":{
      textAlign: "right",
    },
    zIndex: 1,
    "&:hover": {
      color: "#fff",
      backgroundColor: "#5F0AE5",
    },
    "&:nth-child(2)": {
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
    },
    "&:last-child": {
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
    },
  },
  "& .triangle": {
    top: "-14px",
    position: "absolute",
    zIndex: 0,
  },
  "& .triangle-svg": {
    fill: "white",
    stroke: "#E2E8F0",
    transition: "0.3s ease-in",
  },
  "& .triangle-down-svg": {
    fill: "white",
    stroke: "#E2E8F0",
    transition: "0.3s ease-in",
  },
});
